<template>
    <div class="form-floating pb-3">
        <label class="floating-label" for="sound">{{$t("audio.sound")}}</label>
        <select v-model="modelValue.sound_id" class="form-control cursor-pointer">
            <option :value="null" disabled selected>{{$t("user.device.chooseSound")}}</option>
            <option v-for="sound in sounds.data"
                    :key="sound.id"
                    :value="sound.id"
                    data-icon="fa-chevron-down">
                {{ sound.name }}
            </option>
        </select>
        <font-awesome-icon icon="fa-chevron-down" class="position-absolute top-50 end-0"/>
    </div>
</template>

<script setup>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useAuthStore} from "@/store/auth";

const props = defineProps({
    modelValue: {
        type: Object,
        default: {},
    },
    selectedOrganisation: {
        type: Number,
        default: null,
    }
})

const authStore = useAuthStore()

const {data: sounds, refresh: refreshSounds} = await useAsyncData('sounds', () =>
    $larafetch(useNuxtApp().$apiRoute('spa.sounds.index'), {
        method: "GET",
        params: {
            organisation_id: (props.selectedOrganisation) ? props.selectedOrganisation : authStore.userData.organisation_id,
        }
    }).catch(errors => {
        return { data: [] }
    })
)

onMounted(() => {
    refreshSounds()
})

watch(() => props.selectedOrganisation, () => {
    if (sounds.value) {
        refreshSounds()
    }
})

watch(() => authStore.soundsUpdated, () => {
    if (authStore.soundsUpdated) {
        refreshSounds()
        authStore.soundsUpdated = false
    }
})
</script>

<style scoped>

</style>