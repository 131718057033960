<template>
    <div>
        <slot/>
        <div class="row form-row">
            <div class="col-sm-6">
                <base-input-floating :label="$t('user.firstName')" :rules="{required: true}" :formErrors="errors"
                                     name="firstname" v-model="modelValue.firstname"/>
            </div>
            <div class="col-sm-6">
                <base-input-floating :label="$t('user.lastName')" :rules="{required: true}" :formErrors="errors"
                                     name="lastname" v-model="modelValue.lastname"/>
            </div>
        </div>
        <base-input-floating :label="$t('user.eMail')" type="email" :rules="{required: true, email: true}"
                             :formErrors="errors"
                             name="email" v-model="modelValue.email"/>

        <!--                <base-input-floating label="Telefon" :formErrors="errors"-->
        <!--                                     name="phone" v-model="modelValue.phone"/>-->

        <div v-if="!modelValue.invitation" class="form-floating pb-3">
            <label class="floating-label" for="language">{{ $t('user.locale') }}</label>
            <select v-model="modelValue.language" class="form-control cursor-pointer">
                <option v-for="locale in locales" :key="locale.code" :value="locale.code">
                    {{ locale.name }}
                </option>
            </select>
            <font-awesome-icon icon="fa-chevron-down"
                               class="position-absolute top-50 end-0"/>
        </div>

        <div v-if="!modelValue.invitation">

            <div v-if="editPassword || useRoute().params.id==='0'">
                <div clasS="row form-row">
                    <div class="col-sm-6">
                        <base-input-floating :label="$t('auth.password.password')" type="password" :formErrors="errors"
                                             name="password" v-model="modelValue.password"/>

                    </div>
                    <div class="col-sm-6">
                        <base-input-floating :label="$t('auth.password.passwordRepeat')" type="password"
                                             :formErrors="errors" name="password_confirmation"
                                             v-model="modelValue.password_confirmation"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-4">
            <base-checkbox v-if="!modelValue.id>0" v-model="modelValue.invitation"
                           name="invitation">
                {{ $t('user.invitation', {firstname: modelValue.firstname, lastname: modelValue.lastname}) }}
            </base-checkbox>
        </div>

        <a v-if="!modelValue.invitation && useRoute().params.id!=='0'" @click="editPassword=!editPassword"
           class="d-inline-block cursor-pointer fw-bold text-decoration-none">
            <small v-if="!editPassword">{{$t("user.changePassword")}}</small>
            <small v-else>{{$t("user.keepPassword")}}</small>
        </a>
    </div>
</template>

<script setup>
const editPassword = ref(false)
const {locales} = useI18n()

const props = defineProps({
    modelValue: {
        type: Object,
        default: null
    },
    errors: [],
})
</script>