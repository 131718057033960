<template>
    <div class="user-image-container">
        <ProfileImage :image="modelValue.image" :title="modelValue.firstname" :class="partOfModal ? 'pt-xs-4 py-xs-2' : 'pt-4 py-2'"/>
        <v-field name="image" vid="image" v-slot="{errors, valid, invalid, validated}">
            <label>
                <div
                    class="btn d-flex justify-content-center align-items-center cursor-pointer border-0 bg-primary rounded-circle edit-img db-shadow"
                    :class="{'modal-img' : partOfModal}">
                    <font-awesome-icon icon="fa-pencil"/>
                </div>
                <input class="d-none" type="file" @change="uploadHandler($event)">
            </label>

            <div v-if="modelValue.image"
                 class="d-flex justify-content-center align-items-center cursor-pointer border-0 bg-primary rounded-circle delete-img db-shadow"
                 @click="modelValue.image = null">
                <font-awesome-icon icon="fa-trash"/>
            </div>

            <slot name="error">
                <div v-if="errors[0]" class="invalid-feedback d-block">
                    {{ errors[0] }}
                </div>
            </slot>
        </v-field>
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: Object
    },

    partOfModal: {
        type: Boolean,
        default: false
    }
})

function uploadHandler(e) {
    let image = e.target.files[0];

    if (e.target.files[0] !== undefined) {
        let reader = new FileReader();
        reader.onloadend = function () {
            props.modelValue.image = reader.result
        }
        reader.readAsDataURL(image)
    } else {
        props.modelValue.image = null
    }
}
</script>

<style scoped>
.user-image-container {
    margin: auto;
    position: relative;
    width: 150px;
}
</style>